import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import CodeBlock from '../Markdown/CodeBlock'
import { monospace } from '../styles/variables'

// Visual components
export const Heading = styled.h2`
  margin-top: 2.5rem;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 3;
  color: inherit;
  border-bottom: 2px solid currentColor;
  letter-spacing: 2px;
  text-transform: uppercase;
`

export const Subheading = styled.h4`
  font-weight: 600;
  font-size: 0.6875rem;
  color: #a9a9a9;
  letter-spacing: 2px;
  line-height: 2.36;
  text-transform: uppercase;
  margin: 1rem 0 0.2rem;
`

export const HeaderName = styled.span`
  font-size: 0.875rem;
  font-family: ${monospace};
`

export const ResponseData = styled.p`
  font-size: 0.93rem;
  line-height: 1.75;
`

export const SuccessCode = styled.span`
  display: inline-block;
  padding: 0.15rem 0.55rem 0.05rem;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.75;
  color: #82bf40;
  border: 1px solid #ddf5c5;
  background: #f6fcf1;
  border-radius: 2px;

  &:before {
    content: '•';
    padding-right: 0.25rem;
    font-size: 1rem;
    line-height: 1;
    color: #b6ea81;
    vertical-align: text-top;
  }
`

export const ParameterTable = styled.div`
  font-size: 0.75rem;
  font-family: ${monospace};
  line-height: 2;
`

// Functional components
export const Sample = ({ sample, theme }) => {
  if (!sample) {return null}

  return (
    <div>
      <Subheading>Example</Subheading>
      <CodeBlock lang="json" theme={theme}>
        {JSON.stringify(JSON.parse(sample), null, 2)}
      </CodeBlock>
    </div>
  )
}

Sample.propTypes = {
  sample: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  theme: PropTypes.object,
}
