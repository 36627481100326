import React from 'react'
import PropTypes from 'prop-types'
import { get, has, keys, reduce } from 'lodash'

import Code from '../Markdown/Code'
import {
  HeaderName,
  Heading,
  ParameterTable,
  ResponseData,
  Sample,
  Subheading,
  SuccessCode,
} from '../Operation/shared-components'
import ResponseFields from '../ResponseFields'

const getSuccessResponseCode = (responses, id) => {
  const nonDefault = Object.keys(responses).filter((item) => item !== 'default')
  if (nonDefault.length > 1) {
    console.warn(`No success response found for ${id}`) // eslint-disable-line no-console
  }
  return nonDefault[0]
}

const getExampleFromSuccessfulResponse = (response) => {
  if (!has(response, 'content')) {
    return null
  }

  const [ contentTypeSelector ] = keys(get(response, 'content'))
  return get(response, `content.${contentTypeSelector}.example`)
}

const getSchemaFromSuccessfulResponse = (response) => {
  if (response.schema) {
    return response.schema
  }

  const [ contentTypeSelector ] = keys(get(response, 'content'))
  const schema = get(response, `content.${contentTypeSelector}.schema`)

  if (has(schema, 'allOf')) {
    schema.properties = reduce(
      schema.allOf,
      (acc, value) => {
        return { ...acc, ...value.properties }
      },
      {}
    )
  }

  return schema
}

const SuccessfulResponse = ({ code, description }) => {
  return (
    <div>
      <Subheading>Status</Subheading>
      <SuccessCode>
        {code.replace('c_', '')} {description}
      </SuccessCode>
    </div>
  )
}

SuccessfulResponse.propTypes = {
  code: PropTypes.string,
  description: PropTypes.string,
  color: PropTypes.string,
}

const HTTPHeaders = ({ headers, color }) => {
  if (!headers || !Object.keys(headers).length) {
    return null
  }

  return (
    <div>
      <Subheading>Headers</Subheading>
      {Object.keys(headers).map((name) => {
        const header = headers[name]
        return (
          <ResponseData key={name}>
            <HeaderName>{name}</HeaderName>
            <Code color={color} style={{ margin: '0 .5rem' }}>
              {header.type}
            </Code>
            {header.description}
          </ResponseData>
        )
      })}
    </div>
  )
}

HTTPHeaders.propTypes = {
  headers: PropTypes.object,
  color: PropTypes.string,
}

const Schema = ({ schema, theme }) => {
  if (!schema || !Object.keys(schema).length) {
    return null
  }

  return (
    <div>
      <Subheading>Schema</Subheading>
      <ResponseData>{schema.description}</ResponseData>
      {schema && (
        <ParameterTable>
          <ResponseFields definition={{ schema }} theme={theme} />
        </ParameterTable>
      )}
    </div>
  )
}

Schema.propTypes = {
  schema: PropTypes.object,
  color: PropTypes.string,
}

const Response = ({ operation, theme }) => {
  const responses = JSON.parse(operation.operation.responses)
  const successCode = getSuccessResponseCode(responses)
  const response = responses[successCode]
  const schema = getSchemaFromSuccessfulResponse(response)
  const { headers, description } = response
  const example = JSON.stringify(getExampleFromSuccessfulResponse(response))

  return (
    <div>
      <Heading>Response</Heading>
      <SuccessfulResponse code={successCode} description={description} />
      <HTTPHeaders headers={headers} color={theme.color} />
      <Schema schema={schema} theme={theme} />
      <Sample sample={operation.example.response || example} theme={theme} />
    </div>
  )
}

Response.propTypes = {
  theme: PropTypes.object,
  operation: PropTypes.object,
  id: PropTypes.string,
}

export default Response
