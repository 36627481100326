import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ToggleSvgIcon from '../icons/toggle'
import Markdown from '../Markdown/renderer'

import {
  Row,
  ParameterName,
  ParameterValue,
  ArrayFont,
  RequiredFont,
  Enum,
  ParameterDetails,
  SpanWithRightMargin,
  ExpandWrapper,
  ToggleIcon,
} from './visual-components'

class SchemaProperty extends Component {
  state = {
    expanded: false,
  }

  toggleExpansion = () => {
    this.setState({ expanded: !this.state.expanded })
  }

  renderType() {
    // TODO: don't have required and isRequired...
    const {
      isRequired,
      definition: { type, items, required: defRequired },
    } = this.props

    // This is necessary because a swagger parameter object is not the same as
    // a JSON schema object... :(
    const required =
      (typeof defRequired === 'boolean' ? defRequired : false) || isRequired

    return (
      <div>
        {type === 'array' && <ArrayFont>array of </ArrayFont>}
        {(type === 'array' && Array.isArray(items) && items[0].type) ||
          (items && items.type) ||
          type}
        {required && [
          ', ',
          // eslint-disable-next-line react/jsx-key
          <RequiredFont>required</RequiredFont>,
        ]}
      </div>
    )
  }

  renderValidValues() {
    const {
      definition: { enum: validValues },
    } = this.props
    if (!validValues) {
      return null
    }
    return (
      <ParameterDetails>
        <SpanWithRightMargin>Valid values:</SpanWithRightMargin>
        {validValues.map((value) => (
          <Enum key={value} value={value} />
        ))}
      </ParameterDetails>
    )
  }

  renderDefaultValue() {
    const {
      definition: { default: defaultValue },
    } = this.props
    if (!defaultValue) {
      return null
    }
    return (
      <ParameterDetails>
        <SpanWithRightMargin>Default:</SpanWithRightMargin>
        <Enum value={defaultValue.toString()} />
      </ParameterDetails>
    )
  }

  renderDescription() {
    const { theme, definition } = this.props
    const { description } = definition
    if (!description) {
      return null
    }
    return (
      <ParameterDetails>
        <Markdown text={description} theme={theme} />
      </ParameterDetails>
    )
  }

  render() {
    const { expanded } = this.state
    const {
      definition,
      name,
      level = 0,
      noBorder,
      expandable,
      children,
    } = this.props

    return (
      <Row>
        <ParameterName
          level={level}
          expandable={expandable}
          onClick={expandable && this.toggleExpansion}
        >
          {definition.name || name}
          {expandable && (
            <ToggleIcon expanded={expanded}>
              {' '}
              <ToggleSvgIcon />{' '}
            </ToggleIcon>
          )}
        </ParameterName>
        <ParameterValue noBorder={noBorder}>
          {this.renderType()}
          {this.renderValidValues()}
          {this.renderDefaultValue()}
          {this.renderDescription()}
        </ParameterValue>
        <ExpandWrapper expanded={expanded}>{children}</ExpandWrapper>
      </Row>
    )
  }
}
SchemaProperty.propTypes = {
  name: PropTypes.string.isRequired,
  definition: PropTypes.object,
  // sometimes we got undefined...
  level: PropTypes.number.isRequired,
  isRequired: PropTypes.bool,
  noBorder: PropTypes.bool,
  expandable: PropTypes.bool,
  theme: PropTypes.object,
  children: PropTypes.node,
}

export default SchemaProperty
