import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'

import { getSidebarLinks } from '../../layouts/utils'

import links from './links'
import NavLinks from './NavLinks'
import {
  NavigationGroup,
  NavigationGroupTitle,
  Icon,
  NavigationGroupLink,
} from './visual-components'

const query = graphql`
  query SidebarQuery {
    guides: allMarkdownRemark(
      filter: {
        fileAbsolutePath: {
          regex: "/get-started|create|responses|webhooks|embed/"
        }
      }
    ) {
      edges {
        node {
          fileAbsolutePath
          frontmatter {
            nav_title
            title
            layout
            nav_order
          }
        }
      }
    }
    references: allApiDocOperation {
      edges {
        node {
          api
          category
          pathname
          title
          operation {
            summary
            description
          }
        }
      }
    }
  }
`

const Sidebar = ({ currentPath, onClick }) => {
  const data = useStaticQuery(query)
  const subLinks = getSidebarLinks(data)

  return (
    // eslint-disable-next-line
    <aside onClick={onClick}>
      {links.map(({ title, href, color, icon, category }) => {
        const isActive = currentPath.includes(href)
        return (
          <NavigationGroup key={title}>
            <NavigationGroupTitle to={href} color={color}>
              <Icon>{icon}</Icon>
              <NavigationGroupLink isActive={isActive}>
                {title}
              </NavigationGroupLink>
            </NavigationGroupTitle>
            <NavLinks isActive={isActive} currentPath={currentPath}>
              {subLinks[category]}
            </NavLinks>
          </NavigationGroup>
        )
      })}
    </aside>
  )
}

Sidebar.propTypes = {
  currentPath: PropTypes.string,
  onClick: PropTypes.func,
}

export default Sidebar
